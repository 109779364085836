import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@workos-inc/authkit-react';

import { Sidebar } from '../components/Sidebar';
import { Header } from '../components/Header';
import { Login } from '../pages/Login';
import { AdminDashboard } from '../pages/AdminDashboard';
import { DataConnections } from '../pages/DataConnections';
import { ExistingConnections } from '../pages/ExistingConnections';
import { NewConnection } from '../pages/NewConnection';
import { DataManagement } from '../pages/DataManagement';
import { LiveSyncs } from '../pages/LiveSyncs';
import { NewSync } from '../pages/NewSync';
import { UserManagement } from '../pages/UserManagement';
import { ReportsAnalytics } from '../pages/ReportsAnalytics';
import { ClientPortalSettings } from '../pages/ClientPortalSettings';
import { Settings } from '../pages/Settings';
import { AuditLogs } from '../pages/AuditLogs';
import { Search } from '../pages/Search';
import { Help } from '../pages/Help';
import { Profile } from '../pages/Profile';
import { ProfileCreation } from '../pages/ProfileCreation';

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" replace />;
};

const AuthenticatedLayout: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <div className="relative flex min-h-screen">
      <Sidebar />
      <div className="flex-grow flex flex-col dark:bg-gray-900 lg:ml-64 ml-0">
        <Header />
        <main className="flex-grow p-4 overflow-y-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default function CustomRouter() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public route for login */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes with Authenticated Layout */}
        <Route element={<ProtectedRoute><AuthenticatedLayout><Outlet /></AuthenticatedLayout></ProtectedRoute>}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/data-connections" element={<DataConnections />} />
          <Route path="/admin/existing-connections" element={<ExistingConnections />} />
          <Route path="/admin/new-connection" element={<NewConnection />} />
          <Route path="/admin/data-management" element={<DataManagement />} />
          <Route path="/admin/live-syncs" element={<LiveSyncs />} />
          <Route path="/admin/new-sync" element={<NewSync />} />
          <Route path="/admin/user-management" element={<UserManagement />} />
          <Route path="/admin/reports-analytics" element={<ReportsAnalytics />} />
          <Route path="/admin/client-portal-settings" element={<ClientPortalSettings />} />
          <Route path="/admin/profile-creation" element={<ProfileCreation />} />
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/audit-logs" element={<AuditLogs />} />
          <Route path="/admin/search" element={<Search />} />
          <Route path="/admin/help" element={<Help />} />
          <Route path="/admin/profile" element={<Profile />} />
        </Route>

        {/* Redirect any unmatched routes to /login */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';

export const ProfileCreation: React.FC = () => {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleDropdown = (index: number) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!dropdownRefs.current.some((ref) => ref?.contains(event.target as Node))) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Profile Configuration Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">{t('Profile Creation')}</h2>
        <div className="grid grid-cols-2 gap-6">
          {/* Name Input */}
          <div>
            <label htmlFor="profileName" className="block text-sm font-medium text-gray-700">
              {t('Name')}
            </label>
            <input
              type="text"
              id="profileName"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder={t('Administrator')}
            />
          </div>
          {/* Options */}
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="changeProfilePicture"
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="changeProfilePicture" className="ml-2 text-sm text-gray-700">
                {t('Change Profile Picture')}
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="enableDashboardMenu"
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="enableDashboardMenu" className="ml-2 text-sm text-gray-700">
                {t('Enable Dashboard Menu')}
              </label>
            </div>
          </div>
        </div>
        {/* Upload Section */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700">
            {t('Set A Default Profile Image')}
          </label>
          <div className="mt-2 flex justify-center border-2 border-dashed border-gray-300 rounded-lg h-28 items-center">
            <PlusCircleIcon className="h-10 w-10 text-indigo-500" />
          </div>
        </div>
      </div>

      {/* Module/Menu Permission Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">{t('Module/Menu Permission')}</h2>
          <span className="text-sm text-gray-400">{t('i')}</span>
        </div>
        <div>
          {/* Permission Rows */}
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className="grid grid-cols-4 gap-4 items-center py-2 border-b last:border-b-0 border-gray-200"
            >
              <div className="text-gray-700 font-medium">{t('Module Name')}</div>
              <div className="flex space-x-4 text-indigo-500">
                <button className="hover:underline">{t('View')}</button>
                <button className="hover:underline">{t('Edit')}</button>
                <button className="hover:underline">{t('Delete')}</button>
              </div>
              <div className="relative">
                {/* Dots Menu */}
                <button
                  onClick={() => toggleDropdown(index)}
                  className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100"
                >
                  <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" />
                </button>
                {/* Dropdown Menu */}
                {openDropdown === index && (
                  <div
                    ref={(el) => (dropdownRefs.current[index] = el)}
                    className="absolute top-4 right-10 mt-1 w-40 bg-white shadow-lg rounded-md border border-gray-200 z-10"
                  >
                    <div className="p-2 space-y-2">
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        />
                        <span className="text-sm text-gray-700">{t('View')}</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        />
                        <span className="text-sm text-gray-700">{t('Edit')}</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        />
                        <span className="text-sm text-gray-700">{t('Delete')}</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        />
                        <span className="text-sm text-gray-700">{t('Download')}</span>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <div className="mt-6 flex justify-end space-x-4">
        <button className="px-6 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200">
          {t('Cancel')}
        </button>
        <button className="px-6 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700">
          {t('Save New Profile')}
        </button>
      </div>
    </div>
  );
};
